import clsx from 'clsx';
import { memo } from 'react';

const Container = ({ className, children, fullWidth }: ContainerProps) => {
  return (
    <div
      className={clsx('layout-container', {
        ['max-w-8xl mx-auto w-full p-0']: !fullWidth,
        ['w-full']: fullWidth,
        [`${className}`]: className,
      })}
    >
      {children}
    </div>
  );
};

type ContainerProps = {
  className?: string;
  children: React.ReactNode;
  fullWidth?: boolean;
};

export default memo(Container);
