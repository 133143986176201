import { gql } from '@apollo/client';

export const GET_UNREAD_MESSAGES_COUNT = gql`
  query GET_UNREAD_MESSAGES_COUNT(
    $employeeId: ID
    $employerId: ID
    $type_ne: ENUM_MESSAGES_TYPE
  ) {
    rooms(where: { employee: $employeeId, employer: $employerId }) {
      messages(limit: 1, sort: "createdAt:desc", where: { type_ne: $type_ne }) {
        id
        isRead
        sender {
          id
        }
        isReadEmployee
        isReadEmployer
      }
    }
  }
`;

export const GET_UNREAD_ROOM_COUNT = gql`
  query GET_UNREAD_ROOM_COUNT {
    getUnreadRoomCount {
      status
      message
      data
    }
  }
`;

export const GET_ROOMS = gql`
  query GET_ROOMS(
    $limit: Int!
    $skip: Int!
    $status: ENUM_APPLICATION_STATUS
    $keyword: String
  ) {
    getRooms(
      input: { limit: $limit, skip: $skip, status: $status, keyword: $keyword }
    ) {
      totalItems
      data
    }
  }
`;

export const GET_USERS_ROOMS = gql`
  query GET_USERS_ROOMS($start: Int) {
    adminRooms(start: $start) {
      id
      user {
        id
        role {
          id
          name
        }
        firstName
        lastName
        isDeleted
        avatar {
          url
        }
      }
      messages(limit: 1, sort: "createdAt:desc") {
        id
        createdAt
        isRead
        isReadAdmin
        text
        type
        sender {
          id
          role {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_MESSAGES = gql`
  query GET_MESSAGES(
    $roomId: ID!
    $isChatAdmin: Boolean!
    $limit: Int!
    $skip: Int!
  ) {
    getMessages(
      input: {
        roomId: $roomId
        isChatAdmin: $isChatAdmin
        limit: $limit
        skip: $skip
      }
    ) {
      isChatAdmin
      totalItems
      messages {
        id
        createdAt
        type
        sender {
          id
        }
        text
        isReadEmployee
        isReadEmployer
        file {
          url
          name
          mime
        }
        application {
          id
          status
          applicant {
            id
            isVerify
            isDeleted
            firstName
            lastName
            role {
              name
            }
          }
          job {
            id
            status
            jobType
            topic
            wage
            startDate
            startTime
            endDate
            endTime
            company
            address
            district {
              name_th
            }
            province {
              name_th
            }
            taken
            number
            isDebug
          }
        }
        target {
          id
          role {
            name
          }
          firstName
          lastName
          isDeleted
        }
      }
    }
  }
`;

export const READ_MESSAGE = gql`
  mutation READ_MESSAGE($id: ID!) {
    readMessage(id: $id) {
      status
      message
      data
    }
  }
`;

export const CONFIRM_JOBS = gql`
  mutation CONFIRM_JOBS($id: ID!, $messageId: ID!) {
    confirmJob(id: $id, messageId: $messageId) {
      status
      message
      data
    }
  }
`;

export const SEND_CONFIRMATION = gql`
  mutation SEND_CONFIRMATION($id: ID!, $messageId: ID!) {
    sendConfirmation(id: $id, messageId: $messageId) {
      status
      message
      data
    }
  }
`;

export const REPORT_USER = gql`
  mutation REPORT_USER($target: ID!, $description: String!, $file: Upload!) {
    reportUser(
      input: { target: $target, description: $description, file: $file }
    ) {
      status
      message
    }
  }
`;

export const SEND_FILE_MESSAGE = gql`
  mutation SEND_FILE_MESSAGE(
    $file1: Upload!
    $file2: Upload
    $file3: Upload
    $file4: Upload
    $file5: Upload
    $tempIds: [String]!
    $room: String!
    $isChatAdmin: Boolean!
    $sender: ID!
    $senderRole: String!
    $receiver: ID
    $text: String
    $tempId: String
  ) {
    sendFileMessage(
      input: {
        file1: $file1
        file2: $file2
        file3: $file3
        file4: $file4
        file5: $file5
        tempIds: $tempIds
        room: $room
        isChatAdmin: $isChatAdmin
        sender: $sender
        senderRole: $senderRole
        receiver: $receiver
        text: $text
        tempId: $tempId
      }
    ) {
      message
      status
    }
  }
`;

export const UNSEND_MESSAGE = gql`
  mutation UNSEND_MESSAGE($id: ID!) {
    unsendMessage(id: $id) {
      status
      message
    }
  }
`;
