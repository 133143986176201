'use client';
import React, { ReactNode, useMemo } from 'react';
import { useRouter, usePathname } from 'next/navigation';
import { Divider } from 'antd';
import Image from 'next/image';

const NavigatorBar = ({
  navigators,
  isBack = true,
  sideMenu,
}: NavigatorBarProps) => {
  const { back } = useRouter();
  const pathName = usePathname();

  const myNavigator = useMemo(() => {
    const navagator = navigators.find((item) =>
      pathName.includes(item.pathName),
    );
    return navagator;
  }, [navigators, pathName]);

  return (
    <div>
      {myNavigator && (
        <div>
          <div className="bg-white px-[24px] h-[43px] lg:h-[63px] flex">
            <div className="w-full flex flex-row items-center justify-between lg:justify-start gap-3">
              {isBack && (
                <div
                  className="min-w-max cursor-pointer"
                  onClick={() => back()}
                >
                  <Image
                    src={`/assets/icons/icon-arrow-left.svg`}
                    alt={'arrow'}
                    width={24}
                    height={24}
                  />
                </div>
              )}

              <span
                id="myNavigatorName"
                className="font-medium text-lg text-gray-3 lg:text-dark w-full text-center lg:text-start"
              >
                {myNavigator?.name}
              </span>
              <div className="flex justify-end lg:w-full">
                {sideMenu || myNavigator?.sideMenu}
              </div>
            </div>
          </div>
          <div>
            <Divider className="!m-0" />
          </div>
        </div>
      )}
    </div>
  );
};
export default NavigatorBar;

type NavigatorBarProps = {
  navigators: any[];
  isBack?: boolean;
  sideMenu?: ReactNode;
};
