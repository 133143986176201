import React from 'react';
import NavigatorBar from '../NavigatorBar';
import { TabletAndBelow } from '@/components/shared/Responsive';
import { AUTH_NAVIGATOR } from '../NavigatorBar/components/NavigatorList';
import { useAuth } from '@/lib/hooks/useAuth';
import clsx from 'clsx';

const Navigator = () => {
  const { isLoggedIn } = useAuth();
  return (
    <TabletAndBelow>
      <div className={clsx('fixed z-50 w-full', { 'top-[72px]': isLoggedIn })}>
        <NavigatorBar navigators={AUTH_NAVIGATOR} />
      </div>
    </TabletAndBelow>
  );
};

export default Navigator;
