import { ShareSocialMenu } from '@/components/atoms/NavigatorBar/components/SideMenu';
import { IAUTH_NAVIGATOR } from '@/utils/interfaces/navigator.interface';

export const AUTH_NAVIGATOR: IAUTH_NAVIGATOR[] = [
  { name: 'เข้าสู่ระบบ', pathName: '/login' },
  { name: 'ลืมรหัสผ่าน', pathName: '/forgot-password' },
  { name: 'สมัครสมาชิก', pathName: '/register' },
  { name: 'ตัวอย่างงาน', pathName: '/jobs-preview' },
  { name: 'รายละเอียดงาน', pathName: '/jobs/', sideMenu: <ShareSocialMenu /> },
  { name: 'ค้นหางาน', pathName: '/jobs' },
  { name: 'ยืนยันตัวตน', pathName: '/my-account/verify' },
  { name: 'ข้อมูลส่วนตัว', pathName: '/my-account/edit' },
  { name: 'โปรไฟล์', pathName: '/my-account' },
  { name: 'โปรไฟล์', pathName: '/profile' },
  { name: 'การแจ้งเตือน', pathName: '/notifications' },
  { name: 'งานของฉัน', pathName: '/my-jobs' },
  { name: 'งานโปรด', pathName: '/my-favorites' },
  { name: 'ตั้งค่า Line OA', pathName: '/line-oa' },
  { name: 'ตั้งค่า', pathName: '/settings' },
  { name: 'เปลี่ยนรหัสผ่าน', pathName: '/change-password' },
  // { name: 'แชท', pathName: '/message' },
  { name: 'แท็ก', pathName: '/tag' },
];
