import { useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { usePathname } from 'next/navigation';

import { useAuth } from './useAuth';
import { useSocket } from './useSocket';

import { GET_UNREAD_ROOM_COUNT } from '@/services/gql/messages';

export const useRoom = (): IStoreRoom => {
  const { user } = useAuth();
  const { socket } = useSocket();
  const pathName = usePathname();

  const { data: getUnreadRoomCountData, refetch: getUnreadRoomCountRefetch } =
    useQuery(GET_UNREAD_ROOM_COUNT, {
      skip: user?.id === undefined,
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (!socket) return;

    socket.on('messageNotification', (data) => {
      if (pathName === '/messages' && !data?.unsendMessage) return;
      getUnreadRoomCountRefetch();
    });
    return () => {
      socket.off('messageNotification');
    };
  }, [socket, pathName]);

  const unreadRoomCount = useMemo(() => {
    if (!getUnreadRoomCountData?.getUnreadRoomCount) return 0;

    const { status, data } = getUnreadRoomCountData?.getUnreadRoomCount;

    if (status === 200) {
      return data.count || 0;
    }

    return 0;
  }, [getUnreadRoomCountData]);

  return {
    unreadRoomCount: unreadRoomCount,
    unreadRoomCountRefetch: getUnreadRoomCountRefetch,
  };
};

interface IStoreRoom {
  unreadRoomCount: number;
  unreadRoomCountRefetch: () => void;
}
