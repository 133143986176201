'use client';
import { Drawer } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import { memo, useCallback, useState } from 'react';
import clsx from 'clsx';
import { usePathname, useRouter } from 'next/navigation';

import { useAuth } from '@/lib/hooks/useAuth';
import { TabletAndBelow } from '@/components/shared/Responsive';
import { useNotification } from '@/lib/hooks/useNotification';
import { useRoom } from '@/lib/hooks/useRoom';

import styles from './index.module.scss';
import Logo from '../Logo';

const Badge = memo(({ children }: { children: React.ReactNode }) => {
  return (
    <div className={clsx(styles.badge, styles.small, 'top-0 left-[0.85rem]')}>
      {children}
    </div>
  );
});

const MobileDrawer = ({ menus, scrollY = 0 }: MobileDrawerProps) => {
  const { user, logout } = useAuth();
  const { push } = useRouter();
  const pathname = usePathname();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const { countUnRead } = useNotification({
    enabled: false,
  });
  const { unreadRoomCount } = useRoom();

  const onLink = (link: string, isAnchor?: boolean) => {
    setIsOpenDrawer(false);
    if (!isAnchor) {
      if (link === 'ads') {
        return push(`/messages?room=${user?.admin_room?.id}`);
      }
      return push(link);
    }

    const element = document.getElementById(link);
    if (!element) {
      return push(`/${link}`);
    }
    const offsetTop = element.getBoundingClientRect().top + scrollY;
    window.scroll({
      top: offsetTop - 72,
      behavior: 'smooth',
    });
  };

  const hasAuth = useCallback(
    (auth: string[]) => {
      if (!auth || auth.includes('ALL')) return true;
      return auth.includes(user?.role?.name);
    },
    [user?.role?.name],
  );

  return (
    <TabletAndBelow>
      {(scrollY >= 66 || pathname !== '/') && (
        <Link href="/">
          <Logo size="small" />
        </Link>
      )}

      <Image
        onClick={() => setIsOpenDrawer(true)}
        className="ml-auto cursor-pointer hover:brightness-75"
        src="/assets/icons/icon-hamburger.svg"
        width={24}
        height={17}
        alt="menu"
      />

      <Drawer
        className={styles.drawer}
        placement="right"
        onClose={() => setIsOpenDrawer(false)}
        open={isOpenDrawer}
        closeIcon={
          <Image
            className="hover:brightness-75"
            src="/assets/icons/icon-close.svg"
            width={28}
            height={28}
            alt="close"
          />
        }
      >
        <div className="px-12 py-2">
          <Logo size="medium" />
        </div>

        {menus.map((menu, index: number) => {
          return (
            hasAuth(menu.auth) &&
            menu.showMobile !== false && (
              <ul className="menu relative px-12 py-[10px]" key={index}>
                <li
                  className="flex items-center gap-[10px] px-1 py-2 cursor-pointer rounded-lg hover:bg-blue-50"
                  onClick={() =>
                    menu.to ? onLink(menu.to, menu.isAnchor) : logout()
                  }
                >
                  {menu.icon && (
                    <div className="relative">
                      <Image
                        src={`/assets/icons/icon-${menu.icon}.svg`}
                        width={24}
                        height={24}
                        alt={menu.icon}
                      />

                      {menu.icon === 'notification' && countUnRead > 0 && (
                        <Badge>{countUnRead}</Badge>
                      )}
                      {menu.icon === 'messages' && unreadRoomCount > 0 && (
                        <Badge>{unreadRoomCount}</Badge>
                      )}
                    </div>
                  )}

                  <span>{menu.name}</span>
                </li>
              </ul>
            )
          );
        })}
      </Drawer>
    </TabletAndBelow>
  );
};

Badge.displayName = 'Badge';
type MobileDrawerProps = {
  scrollY?: number;
  menus: any[];
};

export default memo(MobileDrawer);
