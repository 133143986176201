'use client';
import { useMediaQuery } from 'react-responsive';

export const Desktop = ({ children }: ResponsiveProps) => {
  const isDesktop = useMediaQuery({ minWidth: 1081 });
  return isDesktop ? children : null;
};

export const Tablet = ({ children }: ResponsiveProps) => {
  const isTablet = useMediaQuery({ minWidth: 640, maxWidth: 1024 });
  return isTablet ? children : null;
};

export const Mobile = ({ children }: ResponsiveProps) => {
  const isMobile = useMediaQuery({ maxWidth: 639 });
  return isMobile ? children : null;
};

export const Default = ({ children }: ResponsiveProps) => {
  const isNotMobile = useMediaQuery({ minWidth: 640 });
  return isNotMobile ? children : null;
};

export const TabletAndBelow = ({ children }: ResponsiveProps) => {
  const isNotDesktop = useMediaQuery({ maxWidth: 1080 });
  return isNotDesktop ? children : null;
};

type ResponsiveProps = {
  children: React.ReactNode;
};
