export const MOBILE_MENUS = [
  {
    id: 0,
    name: 'หน้าหลัก',
    to: '/',
    icon: 'home',
  },
  {
    id: 1,
    name: 'เกี่ยวกับเรา',
    to: '#about-us',
    isAnchor: true,
    icon: 'info',
  },
  {
    id: 2,
    name: 'ติดต่อลงโฆษณา',
    to: '/register/employer',
    icon: 'ads',
  },
  {
    id: 3,
    name: 'ลงประกาศงาน',
    to: '/register/employer',
    icon: 'volume-high',
  },
  {
    id: 4,
    name: 'หางาน',
    to: '/register/employee',
    icon: 'brief',
  },
  {
    id: 5,
    name: 'เข้าสู่ระบบ / ลงทะเบียน',
    to: '/login',
    icon: 'login',
  },
];

export const AUTH_MENUS = [
  {
    name: 'หน้าหลัก',
    to: '/',
    icon: 'home',
    auth: ['ALL'],
    showMobile: true,
  },
  {
    id: 1,
    name: 'โปรไฟล์',
    to: '/my-account',
    icon: 'profile',
    auth: ['ALL'],
    showMobile: true,
  },
  {
    name: 'แจ้งเตือน',
    to: '/notifications',
    icon: 'notification',
    auth: ['ALL'],
    showMobile: true,
  },
  {
    name: 'แชท',
    to: '/messages',
    icon: 'messages',
    auth: ['ALL'],
    showMobile: true,
  },
  {
    name: 'งานของฉัน',
    to: '/my-jobs',
    icon: 'my-job',
    auth: ['EMPLOYEE'],
  },
  {
    name: 'ลงประกาศงาน',
    to: '/jobs/create',
    icon: 'volume-high',
    auth: ['EMPLOYER', 'COMPANY'],
    showMobile: true,
  },
  {
    name: 'งานประกาศ',
    to: '/my-jobs',
    icon: 'volume-high',
    auth: ['EMPLOYER', 'COMPANY'],
  },
  {
    name: 'จัดการสถานะผู้สมัครงาน',
    to: '/my-jobs?jobType=APPLICATION',
    icon: 'receipt',
    auth: ['EMPLOYER', 'COMPANY'],
    showMobile: true,
  },
  {
    name: 'ติดต่อลงโฆษณา',
    to: 'ads',
    icon: 'ads',
    auth: ['EMPLOYER', 'COMPANY'],
    showMobile: true,
  },
  {
    name: 'การเข้าถึงโฆษณา',
    to: 'dashboard',
    icon: 'global-gradient',
    auth: ['EMPLOYER', 'COMPANY'],
    showMobile: true,
  },
  {
    name: 'หางาน',
    to: '/jobs',
    icon: 'brief',
    auth: ['EMPLOYEE'],
  },
  {
    name: 'งานโปรด',
    to: '/my-favorites',
    icon: 'bookmark',
    auth: ['EMPLOYEE'],
  },
  {
    name: 'ตั้งค่า',
    to: '/settings',
    icon: 'setting-gradient',
    auth: ['ALL'],
    showMobile: true,
  },
  {
    name: 'ออกจากระบบ',
    to: '',
    icon: 'logout',
    auth: ['ALL'],
    showMobile: true,
  },
];
