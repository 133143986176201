import { useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import {
  GET_USER_NOTIFICATIONS,
  GET_NOTIFICATIONS_UNREAD_COUNT,
  READ_NOTIFICATION,
  READ_ALL_NOTIFICATIONS,
} from '@/services/gql/notifications';
import socket from '../socket';

export const useNotification = ({
  limit: _limit,
  page: _page,
  enabled,
}: IUseNotification): IStoreNotification => {
  const { user } = useAuth();
  const { push } = useRouter();
  const variables = {
    userId: user?.id,
    limit: _limit ?? 10,
    page: _page ?? 1,
  };
  const [notifications, setNotifications] = useState({ data: [], total: 0 });
  const [notificationCountUnRead, setNotificationCountUnRead] = useState(0);

  const [readNotification] = useMutation(READ_NOTIFICATION, {
    onCompleted: () => {
      refetchCountUnRead();
    },
  });

  const [readAllNotification, { loading: loadingReadAll }] = useMutation(
    READ_ALL_NOTIFICATIONS,
    {
      onCompleted: () => {
        refetchCountUnRead();
        refetchNotifications();
      },
    },
  );

  const {
    data: countUnRead,
    error: countUnReadError,
    refetch: refetchCountUnRead,
  } = useQuery(GET_NOTIFICATIONS_UNREAD_COUNT, {
    variables: {
      id: user?.id,
    },
    skip: user?.id === undefined,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!socket) return;

    socket.on('newNotificaton', () => {
      refetchCountUnRead();
      refetchNotifications();
    });
  }, [socket]);

  const {
    data: notificationsData,
    error: notificationsError,
    loading: notificationsLoading,
    refetch: refetchNotifications,
    fetchMore: fetchMoreNotifications,
  } = useQuery(GET_USER_NOTIFICATIONS, {
    variables: variables,
    skip: enabled === false || user?.id === undefined,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (notificationsError || user?.id === undefined) return;
    if (notificationsData?.getUserNotifications) {
      setNotifications({
        data: notificationsData?.getUserNotifications?.notifications ?? [],
        total: notificationsData?.getUserNotifications?.totalItems ?? 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsData?.getUserNotifications, notificationsError]);

  useEffect(() => {
    if (countUnReadError || user?.id === undefined) return;

    setNotificationCountUnRead(
      countUnRead?.notificationsConnection?.aggregate?.count ?? 0,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countUnRead?.notificationsConnection, countUnReadError]);

  const onFetchMore = (page: number) => {
    fetchMoreNotifications({
      variables: {
        limit: 10,
        page: page,
        userId: user?.id,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const notifications = {
          getUserNotifications: {
            totalPages: fetchMoreResult.getUserNotifications?.totalPages,
            totalItems: fetchMoreResult.getUserNotifications?.totalItems,
            notifications: [
              ...prev.getUserNotifications?.notifications,
              ...fetchMoreResult.getUserNotifications?.notifications,
            ],
          },
        };
        return notifications;
      },
    });
  };

  const onReadNotification = (id: string) => {
    readNotification({ variables: { id: id } });
  };

  const onReadAllNotification = () => {
    if (loadingReadAll) return;
    readAllNotification();
  };

  const onClickNotification = (item: any) => {
    let redirectPath = '';

    if (!item.isRead) {
      onReadNotification(item?.id);
    }

    // eslint-disable-next-line
    switch (item.type) {
      case 'JOB_INTEREST': {
        if (!item?.job?.id) return;
        redirectPath = `/jobs/${item?.job?.id}`;
        break;
      }
      case 'RATING':
        redirectPath = `/my-account?reviews=true`;
        break;
      case 'VERIFY':
      case 'CONFIRM':
      case 'CONFIRMED':
        redirectPath = `/my-account`;
        break;
    }

    if (redirectPath) {
      push(redirectPath);
    }
  };

  return {
    data: notifications.data,
    totalItems: notifications.total,
    loading: notificationsLoading || user?.id === undefined,
    countUnRead: notificationCountUnRead,
    refetch: refetchNotifications,
    onFetchMore: onFetchMore,
    onClick: onClickNotification,
    onRead: onReadNotification,
    onReadAll: onReadAllNotification,
    loadingReadAll: loadingReadAll,
  };
};

interface IUseNotification {
  limit?: number;
  page?: number;
  enabled?: boolean;
}

interface IStoreNotification {
  data: any[];
  totalItems: number;
  loading: boolean;
  countUnRead: number;
  refetch: (variables?: any) => void;
  onFetchMore: (filter?: any) => void;
  onClick: (item: any) => void;
  onRead: (id: string) => void;
  onReadAll: () => void;
  loadingReadAll?: boolean;
}
