import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS_UNREAD_COUNT = gql`
  query GET_NOTIFICATIONS_UNREAD_COUNT($id: ID!) {
    notificationsConnection(where: { user: $id, isRead: false }) {
      aggregate {
        count
      }
    }
  }
`;

export const READ_NOTIFICATION = gql`
  mutation READ_NOTIFICATION($id: ID!) {
    updateNotification(input: { where: { id: $id }, data: { isRead: true } }) {
      notification {
        id
        isRead
      }
    }
  }
`;

export const READ_ALL_NOTIFICATIONS = gql`
  mutation READ_ALL_NOTIFICATIONS {
    readAllNotifications {
      status
    }
  }
`;

export const GET_USER_NOTIFICATIONS = gql`
  query GET_USER_NOTIFICATIONS($userId: ID!, $limit: Int!, $page: Int!) {
    getUserNotifications(
      input: { userId: $userId, limit: $limit, page: $page }
    ) {
      totalItems
      totalPages
      notifications {
        id
        type
        title
        description
        isRead
        createdAt
        type
        job {
          id
          topic
        }
      }
    }
  }
`;
