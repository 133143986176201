'use client';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { memo, useEffect, useState } from 'react';

import Button from '@/components/atoms/Button';
import { Grid } from 'antd';
import clsx from 'clsx';
import styles from './index.module.scss';

import { Desktop } from '@/components/shared/Responsive';
import { MOBILE_MENUS } from '@/utils/constants/menu';
import { usePathname, useRouter } from 'next/navigation';
import AddCircleIcon from 'public/assets/icons/icon-add-circle.svg';
import Container from '../Container';
import Logo from '../Logo';
import MobileDrawer from './MobileDrawer';
import Navigator from '@/components/atoms/Navigator';

const Header = ({
  className,
  rounded,
  shadow,
  animated,
  scrollY = 0,
}: HeaderProps) => {
  const SCROLL_Y = 70;
  const { push } = useRouter();
  const screens = Grid.useBreakpoint();
  const pathname = usePathname();
  const [menuActive, setMenuActive] = useState('');

  const goTo = (link: string, isAnchor?: boolean) => {
    if (!isAnchor) return push(link);

    const element = document.getElementById(link);
    if (!element) {
      return push(`/#${link}`);
    }
    const offsetTop = element.getBoundingClientRect().top + scrollY;
    window.scroll({
      top: offsetTop - 72,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (pathname !== '/') return;

    const aboutus = document.getElementById('about-us');

    if (!aboutus) return;

    const offsetTop = aboutus.getBoundingClientRect().top + scrollY;

    const top = offsetTop - 100;
    const topXxl = offsetTop - 700;

    if (scrollY >= top) {
      if (menuActive === '#about-us') return;
      setMenuActive('#about-us');
    } else if (screens.xxl && scrollY >= topXxl) {
      setMenuActive('#about-us');
    } else {
      if (menuActive === '/') return;
      setMenuActive('/');
    }
  }, [menuActive, pathname, scrollY]);

  useEffect(() => {
    if (window.location.hash && window.location.hash === 'about-us') {
      return goTo(window.location.hash.split('#')[1], true);
    }
    if (menuActive === pathname) return;
    setMenuActive(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const isPositionTop = scrollY < SCROLL_Y;
  const isMobile = screens.xl === undefined ? true : !screens.xl;

  const variants = {
    animate: {
      top: isPositionTop ? '56px' : '0',
      paddingLeft: isPositionTop ? '100px' : '0',
      paddingRight: isPositionTop ? '100px' : '0',
      transition: { duration: 0.5, ease: 'easeIn' },
    },
    initial: {
      top: isPositionTop ? '0' : '56px',
      paddingLeft: '100px',
      paddingRight: '100px',
    },
  };

  return (
    <>
      <motion.header
        className={clsx('w-full bg-transparent z-[99] fixed', {
          [`${className}`]: !!className,
        })}
        variants={variants}
        initial={isMobile || !animated ? {} : 'initial'}
        animate={isMobile || !animated ? {} : 'animate'}
      >
        <nav
          className={clsx(styles.navbar, {
            'xl:rounded-[14px]': rounded && isPositionTop,
            [`${styles.primaryShadow}`]: shadow === 'primary',
            [`${styles.secondaryShadow}`]: shadow === 'secondary',
          })}
        >
          {/* Desktop */}
          <Desktop>
            <Container className="flex items-center">
              <Link href="/">
                <Logo
                  className="mr-8 cursor-pointer hover:brightness-90"
                  size="small"
                />
              </Link>

              <span
                onClick={() => goTo('/')}
                className={clsx(styles.menuItem, {
                  [`${styles.isActive}`]: menuActive === '/',
                })}
              >
                หน้าแรก
              </span>

              <span
                onClick={() => goTo('about-us', true)}
                className={clsx(styles.menuItem, {
                  [`${styles.isActive}`]: menuActive === '#about-us',
                })}
              >
                เกี่ยวกับเรา
              </span>

              <span className={styles.menuIndicator}></span>
              <div className="ml-auto gap-6 flex items-center">
                <span
                  onClick={() => goTo('/login')}
                  className={clsx(styles.menuItem, {
                    [`${styles.isActive}`]: ['/login', '/register'].includes(
                      menuActive,
                    ),
                  })}
                >
                  เข้าสู่ระบบ/ลงทะเบียน
                </span>

                <Button
                  className="w-[128px]"
                  ghost
                  onClick={() => goTo('/register/employee')}
                >
                  หางาน
                </Button>
                <Button
                  type="primary"
                  icon={<AddCircleIcon className="[&>g]:stroke-white mr-1" />}
                  onClick={() => goTo(`/register/employer`)}
                >
                  ลงประกาศหางาน
                </Button>
              </div>
            </Container>
          </Desktop>

          {/* Tablet and Mobile */}
          <MobileDrawer scrollY={scrollY} menus={MOBILE_MENUS} />
        </nav>
        <Navigator />
      </motion.header>
    </>
  );
};

export type HeaderProps = {
  className?: string;
  rounded?: boolean;
  shadow?: 'primary' | 'secondary';
  animated?: boolean;
  scrollY?: number;
};

export default memo(Header);
