'use client';
import { CopyOutlined } from '@ant-design/icons';
import { Divider, Popover, notification } from 'antd';
import { PopoverProps } from 'antd/lib';
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
} from 'next-share';
import Image from 'next/image';
import { memo, useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './index.module.scss';
import { RESPONSE_MESSAGE } from '@/utils/constants/responseMessage';

const ShareList = memo(({ onClose, link }: ShareListProps) => {
  const [api, contextHolder] = notification.useNotification();

  const onCopy = useCallback(() => {
    api.success({
      icon: <CopyOutlined />,
      message: RESPONSE_MESSAGE.COPY_TITLE,
      description: RESPONSE_MESSAGE.COPIED,
    });
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onShare = () => {
    onClose();
  };

  return (
    <div className="flex flex-col justify-center">
      {contextHolder}
      <FacebookShareButton url={link} onClick={() => onShare()}>
        <div className="flex items-center cursor-pointer gap-2 hover:bg-blue-50 p-2 rounded">
          <Image
            src="/assets/icons/icon-share-facebook.svg"
            width={11}
            height={11}
            alt="facebook"
          />
          <span className="text-sm text-dark">Facebook Feed</span>
        </div>
      </FacebookShareButton>

      <LineShareButton url={link} onClick={() => onShare()}>
        <div className="flex items-center cursor-pointer gap-2 hover:bg-blue-50 p-2 rounded">
          <Image
            src="/assets/icons/icon-share-line.svg"
            width={18}
            height={18}
            alt="line"
          />
          <span className="text-sm text-dark">LINE</span>
        </div>
      </LineShareButton>

      <TwitterShareButton url={link} onClick={() => onShare()}>
        <div className="flex items-center cursor-pointer gap-2 hover:bg-blue-50 p-2 rounded">
          <Image
            src="/assets/icons/icon-share-twitter.svg"
            width={18}
            height={18}
            alt="twitter"
          />
          <span className="text-sm text-dark">Twitter</span>
        </div>
      </TwitterShareButton>

      <Divider className="!my-3" />
      <span className="text-xs text-gray-300 font-normal uppercase">
        Share via Link
      </span>
      <CopyToClipboard text={link} onCopy={() => onCopy()}>
        <div className="flex items-center cursor-pointer gap-2 hover:bg-blue-50 p-2 rounded">
          <Image
            src="/assets/icons/icon-share-link.svg"
            width={18}
            height={18}
            alt="link"
          />
          <span className="text-sm text-dark">Copy Link</span>
        </div>
      </CopyToClipboard>
    </div>
  );
});

const SocialShare = ({ link, disabled }: ISocialShareProps) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Popover
      title="Share To"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      rootClassName={styles.socialShare}
      content={<ShareList onClose={onClose} link={link} />}
    >
      <div className="relative flex-center hover:opacity-70 cursor-pointer h-[26px] w-[26px] sm:h-[24px] sm:w-[24px]">
        <Image src="/assets/icons/icon-share.svg" alt="share" fill />
      </div>
    </Popover>
  );
};

ShareList.displayName = 'ShareList';

type ShareListProps = {
  link: string;
  onClose: () => void;
  disabled?: boolean;
};

interface ISocialShareProps extends PopoverProps {
  link: string;
  disabled?: boolean;
}

export default memo(SocialShare);
