import { HTMLProps, memo } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import styles from './index.module.scss';

const Logo = ({ size = 'large', className, animated }: LogoProps) => {
  return (
    <motion.h1
      className={clsx(styles.logo, {
        [`${className}`]: !!className,
        [styles.logoLarge]: size === 'large',
        [styles.logoMedium]: size === 'medium',
        [styles.logoSmall]: size === 'small',
      })}
      initial={{
        background:
          'linear-gradient(90deg, #02857F 0%, #F1B359 8%, #0278D6 80.07%)',
      }}
      animate={{
        background:
          'linear-gradient(90deg, #02857F 20.51%, #F1B359 58.81%, #0278D6 96.07%)',
      }}
      transition={{
        duration: animated ? 1 : 0,
        ease: 'linear',
        repeatType: 'reverse',
      }}
    >
      Medimatch
    </motion.h1>
  );
};

type LogoProps = {
  size?: 'large' | 'medium' | 'small';
  className?: HTMLProps<HTMLElement>['className'];
  animated?: boolean;
};

export default memo(Logo);
